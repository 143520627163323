<template>
  <div>
    <section class="px-4 py-17 md:py-28 md:px-10 lg:px-30">
      <div class="grid grid-cols-1 md:grid-cols-12 gap-2 bg-gray-800">
        <div class="col-span-5">
          <div class="prod-px">
            <div class="img-container">
              <img :src="getImg()" />
            </div>
          </div>
        </div>
        <div class="col-span-7 specs-container">
          <div class="wrapper">
            <div class="specs-heading">
              {{ element.title }}
            </div>
            <div class="specs-wrapper">

              <span class="specs-element">
                <span class="cellA">
                  Тип
                </span>
                <span class="cellB">
                  {{ element.description.type }}
                </span>
              </span>

              <span v-if="element.description.color" class="specs-element">
                <span class="cellA">
                  Цвет
                </span>
                <span class="cellB">
                  {{ element.description.color }}
                </span>
              </span>

              <span v-if="element.description.volume" class="specs-element">
                <span class="cellA">
                  Общий объем:
                </span>
                <span class="cellB">
                  {{ element.description.volume }}
                </span>
              </span>

              <span v-if="element.description.camera" class="specs-element">
                <span class="cellA">
                  Размораживание морозильной камеры
                </span>
                <span class="cellB">
                  {{ element.description.camera }}
                </span>
              </span>
              <span v-if="element.description.cameraCount" class="specs-element">
                <span class="cellA">
                  Количество камер
                </span>
                <span class="cellB">
                  {{ element.description.cameraCount }}
                </span>
              </span>
              <span v-if="element.description.power" class="specs-element">
                <span class="cellA">
                  Мощность замораживания
                </span>
                <span class="cellB">
                  {{ element.description.power }}
                </span>
              </span>

              <span v-if="element.description.class" class="specs-element">
                <span class="cellA">
                  Климатический класс
                </span>
                <span class="cellB">
                  {{ element.description.class }}
                </span>
              </span>

              <span v-if="element.description.classElectricity" class="specs-element">
                <span class="cellA">
                  Класс энергопотребления
                </span>
                <span class="cellB">
                  {{ element.description.classElectricity }}
                </span>
              </span>

              <span v-if="element.description.cylinderCapacity" class="specs-element">
                <span class="cellA">
                  Емкость цилиндра
                </span>
                <span class="cellB">
                  {{ element.description.cylinderCapacity }}
                </span>
              </span>

              <span v-if="element.description.dsv" class="specs-element">
                <span class="cellA">
                  ДxШxВ
                </span>
                <span class="cellB">
                  {{ element.description.dsv }}
                </span>
              </span>
              <span v-if="element.description.weight" class="specs-element">
                <span class="cellA">
                  Вес
                </span>
                <span class="cellB">
                  {{ element.description.weight }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {products} from "../data/products";
export default {
  name: "ProductPage",
  data() {
    return {
      products,
      id: this.$route.params.id,
    }
  },
  methods: {
    getImg() {
      return require('./../assets/img/products/' + this.products[this.id].imgMin);
    }
  },
  computed: {
    element() {
      return this.products[this.id];
    }
  }
}
</script>

<style scoped>
.prod-px {
  min-height: 490px;
  z-index: 4;
  position: relative;
  width: 100%;
  padding: 12px;
}
.img-container {
  /*border: 1px solid #ededed;*/
}
.specs-container {
  float: right;
  padding: 15px 15px 15px 20px;
}
.specs-wrapper {
  /*display: flex;*/
  /*flex-direction: column;*/
  display: table;
  width: 100%;
}
.specs-element {
  display: table;
  width: 100%;
}
.cellA {
  font-weight: 400;
  color: #9e9e9e;
  height: 100%;
  width: 20%;
  display: table-cell;
  float: none;
  padding: 15px 10px 15px 0;
  font-size: 16px;
}
.cellB {
  width: 80%;
  color: #fff;
  word-break: break-word;
  height: 100%;
  display: table-cell;
  float: none;
  padding: 15px 10px;
  font-size: 16px;
}
.wrapper {
  padding-bottom: 5px;
  float: left;
  width: 100%;
}
.specs-heading {
  border-bottom: 1px dashed #ddd;
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  padding: 30px 0 15px;
}
</style>